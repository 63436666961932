var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showCompanyOverview
    ? _c("div", { attrs: { id: "company-overview" } }, [
        _c("h3", [_vm._v("\n    My Company Information\n  ")]),
        _c("hr"),
        _c(
          "div",
          { attrs: { id: "company-details-panel" } },
          [
            _c(
              "b-tabs",
              {
                attrs: { pills: "", card: "", vertical: "" },
                model: {
                  value: _vm.tabIndex,
                  callback: function ($$v) {
                    _vm.tabIndex = $$v
                  },
                  expression: "tabIndex",
                },
              },
              [
                _c(
                  "b-tab",
                  {
                    ref: "companyDetailsTab",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c("feather-icon", {
                                attrs: {
                                  type: "briefcase",
                                  stroke: _vm.getStrokeColor(0),
                                },
                              }),
                              _vm._v("\n          Company Details\n        "),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3796217232
                    ),
                  },
                  [
                    _c("company-detail-section", {
                      staticClass: "mb-4",
                      attrs: {
                        id: "registration-details",
                        "section-title": "Registration",
                        "schema-key": "registrationSchema",
                        "read-only": true,
                      },
                    }),
                    _c("company-detail-section", {
                      attrs: {
                        id: "contact-details",
                        "section-title": "Contact",
                        "schema-key": "companySchema",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-tab",
                  {
                    ref: "companyPeopleTab",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c("feather-icon", {
                                attrs: {
                                  type: "users",
                                  stroke: _vm.getStrokeColor(1),
                                },
                              }),
                              _vm._v("\n          Company People\n        "),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3101868636
                    ),
                  },
                  [
                    _c("company-detail-section", {
                      attrs: {
                        id: "people-details",
                        "section-title": "People",
                        "schema-key": "peopleSchema",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }