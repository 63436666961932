<template>
  <div v-if="showCompanyOverview" id="company-overview">
    <h3>
      My Company Information
    </h3>

    <hr>

    <div id="company-details-panel">
      <b-tabs v-model="tabIndex" pills card vertical>
        <b-tab ref="companyDetailsTab">
          <template #title>
            <feather-icon
              type="briefcase"
              :stroke="getStrokeColor(0)"
            />
            Company Details
          </template>

          <company-detail-section
            id="registration-details"
            section-title="Registration"
            schema-key="registrationSchema"
            :read-only="true"
            class="mb-4"
          />
          <company-detail-section
            id="contact-details"
            section-title="Contact"
            schema-key="companySchema"
          />
        </b-tab>

        <b-tab ref="companyPeopleTab">
          <template #title>
            <feather-icon
              type="users"
              :stroke="getStrokeColor(1)"
            />
            Company People
          </template>

          <company-detail-section
            id="people-details"
            section-title="People"
            schema-key="peopleSchema"
          />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "CompanyOverview",

  components: {
    FeatherIcon:          () => import('@/components/shared/FeatherIcon'),
    CompanyDetailSection: () => import('@/components/StagelineV2/companyOverview/CompanyDetailSection.vue'),
  },

  data() {
    return {
      loaded: false,
      tabIndex: 0,
    }
  },

  computed: {
    ...mapGetters('stagelineCompanyOverview', [
      'schema',
    ]),
    showCompanyOverview() {
      return this.schema?.length && this.loaded
    },
  },

  async mounted() {
    this.loaded = false
    await this.initializeSchema()
    this.loaded = true
  },

  methods: {
    ...mapActions('stagelineCompanyOverview', [
      'loadCompanyDetailSchema',
      'processCompanyDetails',
    ]),
    async initializeSchema() {
      await this.loadCompanyDetailSchema()
      await this.processCompanyDetails()
    },
    getStrokeColor(tabIndex) {
      return this.tabIndex === tabIndex ? "#2fb2ae" : "#000000"
    },
  },
}
</script>

<style lang="scss" scoped>
#company-overview {
  width: 100%;
  display: block;
}

hr {
  border-top: 3px solid rgba(0, 0, 0, 0.1);
}

#company-details-panel {
  border: 1px solid $ct-ui-color-20;
  border-radius: .2em;

  > .tabs {
    margin-bottom: 0;

    ::v-deep .col-auto {
      background-color: #F7F7FB;
    }

    ::v-deep .nav-link {
      &:not(.active) {
        color: black;
      }
      &.active {
        background-color: white;
        color: $ct-ui-primary;
        border: 1px solid $ct-ui-primary;
      }
    }
  }
}

@media (max-width: 659px) {
  #company-details-panel {
    > .tabs {
      ::v-deep .col-auto {
        width: 100%;
      }
    }
  }
}
</style>
